import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import './DecisionTreeEditorPage.scss';
import { TabMenu } from "primereact/tabmenu";
import Canvas from "./decision-tree/canvas";
import CodeEditorJS from "./code-editor/CodeEditorJS";
import CodeEditorHTML from "./code-editor/CodeEditorHTML";
import RequirementEditor from "./requirement-editor/RequirementEditor";
import FormEditor from "./form-editor/FormEditor";
import DiagramEditor from "./diagram-editor/DiagramEditor";

interface Props extends WithTranslation {
}

interface States {
  activeTool: any;
}

enum Lang {
  HTML = "html",
  JS = "script",
}

class DecisionTreeEditorPage extends React.Component<Props, States> {

  MIN_ZOOM_LVL: number = 0;
  MAX_ZOOM_LVL: number = 190; //absolute max is 199
  ZOOM_FACTOR: number = 100;
  zoomLevel: number = 100;

  canvas: any; // decision-tree canvas
  canvasPosition: any; // decision-tree canvas position
  selectedShape: any; // decision-tree shape
  displayShapeForm: boolean = false;
  isReadOnly: boolean = false;


  private menuItems: any[];

  private toast: Toast | null;

  constructor(props: Props) {
    super(props);
    this.toast = null;
    this.state = {
      activeTool: "form",
    };
    this.menuItems = [
      {
        //label: 'Formulaire',
        icon: 'formIcon',
        target: 'form'
      },
      {
        //label: 'Exigence', 
        icon: 'requirementIcon',
        target: 'requirement'
      },
      {
        //label: 'Arbre',
        icon: 'treeIcon',
        target: 'tree'
      },
      {
        //label: 'Script',
        icon: 'scriptIcon',
        target: 'script'
      },
      {
        //label: 'Html',
        icon: 'htmlIcon',
        target: 'html'

      },
      {
        icon: "pi pi-sitemap",
        target: "diagram"
      }
    ];
    this.state = {
      activeTool: this.menuItems[0],
    };

  }

  render() {
    return (
      <div className="mainDecisionTreeEditor">
        <div className="topLigne"/>

        {/* NAVIGATOR*/}
        <div className="tabMenu">
          <TabMenu
            model={this.menuItems}
            activeItem={this.state.activeTool}
            onTabChange={e => this.setState({ activeTool: e.value })}
          />
        </div>

        {
          this.state.activeTool.target === "form" &&
            <>
                <FormEditor/>
            </>
        }

        {this.state.activeTool.target === "requirement" &&
            <>
                <RequirementEditor/>
            </>
        }

        {this.state.activeTool.target === "tree" &&
            <>
                <Canvas/>
            </>
        }

        {this.state.activeTool.target === "script" &&
            <>
                <CodeEditorJS/>
            </>
        }
        {this.state.activeTool.target === "html" &&
            <>
                <CodeEditorHTML/>
            </>
        }
        {this.state.activeTool.target === "diagram" &&
            <>
                <DiagramEditor/>
            </>
        }

      </div>
    );
  }
}

export default withTranslation()(DecisionTreeEditorPage);
