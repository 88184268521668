import { withTranslation, WithTranslation } from "react-i18next";
import React, { ReactNode } from "react";
import { TFunction } from "i18next";
import { FormService } from "../services/FormService";
import { FormQuestion } from "../models/FormQuestion";
import "./FormTable.scss";
import { QuestionType } from "../enum/QuestionType";
import FormInputNumber from "./FormInputNumber";
import FormDropdown from "./FormDropdown";
import { Button } from "primereact/button";
import { Input } from "@bbri/ui";

interface States {
  values: any[],
  size: number,
  dropDownValues: {};
}


interface Props extends WithTranslation {
  question: FormQuestion;
  currentValues: string[];
  values: any[];
  onValueChange: (v: any) => void;
  name: string;
  questionHistory: any;
  questions: any;
  onSizeChange: () => void;
}

class FormTable extends React.Component<Props, States> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;

    if (this.props.question.size.type === "previous_question") {
      if (typeof this.props.questionHistory[this.props.question.size.target] === 'string') {
        this.state = {
          values: this.props.values ?? [],
          size: this.props.questionHistory[this.props.question.size.target],
          dropDownValues: {}
        };
      }
      else {
        this.state = {
          values: this.props.values ?? [],
          size: this.props.questionHistory[this.props.question.size.target]?.length ?? 0,
          dropDownValues: {}
        };
      }
    }
    else {
      let length;
      if (this.props.values?.length) {
        length = this.props.values?.length;
      }
      else if (this.props.question.size.value) {
        length = this.props.question.size.value;
      }
      else {
        length = this.props.question.size;
      }
      this.state = {
        values: this.props.values ?? [],
        size: (this.props.question.size.type === 'dynamic') ? (this.props.question.size.min ?? this.props.values?.length) : length,
        dropDownValues: {}
      };
    }
    if (!this.props.values) {
      for (let i = 0; i < this.state.size; i++) {
        this.state.values.push({});
      }
    }
    if (this.props.values) {
      // TODO CHECK
    }
  }

  componentDidMount(): void {
    this.setState({
      values: this.props.values
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if ((this.props.question.question !== prevProps.question.question) && this.props.questionHistory[this.props.question.question]?.length === 0) {
      this.setState({
        values: this.props.values
      });
    }
  }

  onValueChange(v: any) {
    //this.setState({...this.state});
  }

  onResultChange(subQuestion: FormQuestion, value: any, column: number, row: number) {
    if ((JSON.stringify(this.props.values) !== JSON.stringify(this.state.values))) {
      this.setState({
        values: this.props.values
      });
    }

    if (!this.state.values[row]) {
      for (let i = 0; i <= row; i++) {
        if (!this.state.values[i]) {
          this.state.values.push({});
        }
        if (i === row) {
          this.state.values[row][subQuestion.question] = value;
        }
      }
    }
    else {
      this.setState({
        values: [
          ...this.props.values.slice(0, row),
          Object.assign(this.props.values[row], { [subQuestion.question]: value }),
          ...this.props.values.slice(row + 1)
        ]
      });
    }
  }

  private _createTableBody() {
    const list: ReactNode[] = [];
    for (let row = 0; row < this.state.size; row++) {
      if (this.props.question.subQuestions) {
        list.push(<tr key={row}>{this._createTableLine(row)}</tr>);
      }
    }
    return list;
  }

  private _createTableLine(row: number) {
    const list: ReactNode[] = [];

    if (this.props.question.subQuestions) {
      for (let column = 0; column < this.props.question.subQuestions.length; column++) {
        if (this.props.question.subQuestions[column].type === QuestionType.PREVIOUS_QUESTION) {

          for (const question of this.props.questions) {
            if (question.question === this.props.question.subQuestions[column].target) {
              for (const subQ of question.subQuestions) {
                if (subQ.question === this.props.question.subQuestions[column].question) {
                  list.push(this._getHtml(subQ, row, column, this.props.questionHistory[this.props.question.subQuestions[column].target]));
                }
              }
            }
          }

        }
        else if (this.props.question.subQuestions[column].type === QuestionType.DECISION_TREE) {
          list.push(this._getHtml(this.props.question.subQuestions[column], row, column, ((this.props.values[row]) ? this.props.values[row][this.props.question.subQuestions[column].question] : null)));
        }
        else {
          list.push(this._getHtml(this.props.question.subQuestions[column], row, column));
        }
      }
    }

    return list;
  }

  private _createTableHeader() {
    const list: ReactNode[] = [];

    if (this.props.question.subQuestions) {
      for (let i = 0; i < this.props.question.subQuestions.length; i++) {
        const subQuestion = this.props.question.subQuestions[i];

        list.push(<th key={i}>{this.formService.t(this.t, subQuestion.question, true, false)}</th>);
      }
    }

    return list;
  }

  static getDerivedStateFromProps(props: any, state: any) {
    if ((JSON.stringify(props.values) !== JSON.stringify(state.values))) {
      return {
        ...state,
        values: props.values
      };
    }
    return state;
  }

  render() {

    const list: ReactNode[] = [];
    let button: any[] = [];
    if (this.props.question.size.type && this.props.question.size.type === 'dynamic') {
      button.push(<div className="button">
        <Button
          icon="pi pi-plus"
          onClick={() => this.addRow()}
        />
        <Button
          icon="pi pi-minus"
          onClick={() => this.rmRow()}
        />
      </div>);
    }

    list.push(
      <div key={`div-${this.props.question.type}`} className="content">
        <table key={`table-${this.props.question.type}`} className="formTable">
          <thead>
          <tr>
            {this._createTableHeader()}
          </tr>
          </thead>
          <tbody>
          {this._createTableBody()}
          </tbody>
        </table>
        {button}
      </div>
    );
    return list;
  }

  private addRow() {
    if (this.props.question.size.max && this.state.size < this.props.question.size.max) {
      this.setState({
        size: this.state.size + 1
      });
      this.props.values.push({});
      this.props.onSizeChange();
    }
  }


  private rmRow() {
    if ((this.props.question.size.min && this.state.size > this.props.question.size.min)) {
      this.rmRowAction();
    }
    else if (!this.props.question.size.min && this.state.size > 0) {
      this.rmRowAction();
    }
  }

  private rmRowAction() {
    this.setState({
      size: this.state.size - 1
    });
    if (Object.keys(this.state.dropDownValues).length > this.state.size) {
      this.setState({
        ...this.state.dropDownValues,
        [this.state.size]: undefined
      });
    }
    this.props.values.pop();
    this.props.onSizeChange();
  }

  private _getHtml(formQuestion: FormQuestion, row: number, column: number, values?: any) {
    const key = `${formQuestion.type}-${row}-${column}`;

    switch (formQuestion.type) {
      case QuestionType.STRING:
        if (!values) {
          return (
            <td key={key} className={formQuestion.type} style={formQuestion.width ? { width: formQuestion.width ?? '' } : {}}>
              <Input.Text

                id={formQuestion.question + '-' + row}
                type="text"
                value={
                  (
                    (this.props.values && this.props.values[row] && this.props.values[row][formQuestion.question]) ?
                      this.props.values[row][formQuestion.question] : '')}
                onChange={(v) => {
                  this.props.onValueChange(this.state.values);
                  if (this.props.question.subQuestions) {
                    this.onResultChange(formQuestion, (v.target as any).value, column, row);
                  }
                }}
                disabled={values}
              />
            </td>);
        }
        else {
          return (
            <td key={key} className={formQuestion.type} style={formQuestion.width ? { width: formQuestion.width ?? '', textAlign: 'center' } : { textAlign: 'center' }}>
              <p>{values[row] ? values[row][formQuestion.question] : ''}</p>
            </td>
          );
        }
      case QuestionType.NUMBER:
        if (!values) {
          return (
            <td key={key} className={formQuestion.type} style={formQuestion.width ? { width: formQuestion.width ?? '' } : {}}>
              <FormInputNumber
                values={this.props.questionHistory}
                question={formQuestion}
                parentQuestion={this.props.question}
                value={(this.props.values?.length > 0 && this.props.values[row] && this.props.values[row][formQuestion.question]) ? this.props.values[row][formQuestion.question] : 0}
                onValueChange={(v) => {
                  if (this.props.question.subQuestions) {
                    this.onResultChange(formQuestion, v, column, row);
                  }
                  this.props.onValueChange(this.state.values);
                }}
                onBlur={(question, v) => {
                  return this.onValueChange;
                }}
                disabled={values}
              />
            </td>
          );
        }
        else {
          return (
            <td key={key} className={formQuestion.type} style={formQuestion.width ? { width: formQuestion.width ?? '', textAlign: 'center' } : { textAlign: 'center' }}>
              <p>{(values[row]) ? values[row][formQuestion.question] : null}</p>
            </td>
          );
        }
      case QuestionType.DROPDOWN:
        if (!values) {
          return (
            <td key={key} className={formQuestion.type}
                style={formQuestion.width ? { width: formQuestion.width ?? '' } : {}}>
              <FormDropdown
                name={formQuestion.question}
                currentValue={(this.props.values && this.props.values[row] && this.props.values[row][formQuestion.question]) ? this.props.values[row][formQuestion.question] : null}
                values={formQuestion.values}

                onValueChange={(v) => {
                  this.props.onValueChange(this.state.values);
                  this.setState({
                    dropDownValues: {
                      ...this.state.dropDownValues,
                      [row]: v
                    }
                  });
                  if (this.props.question.subQuestions) {
                    this.onResultChange(formQuestion, v, column, row);
                  }
                }}
                disabled={values}
              />
            </td>
          );
        }
        else {
          return (
            <td key={key} className={formQuestion.type} style={formQuestion.width ? { width: formQuestion.width ?? '', textAlign: 'center' } : { textAlign: 'center' }}>
              <p>{values[row][formQuestion.question]}</p>
            </td>
          );
        }

      case QuestionType.DECISION_TREE:
        return (
          <td key={key} className={formQuestion.type} style={formQuestion.width ? { width: formQuestion.width ?? '', textAlign: 'center' } : { textAlign: 'center' }}>
            <p>{values}</p>
          </td>
        );
    }
  }
}

export default withTranslation()(FormTable);
