import { Button, Input, Question } from "@bbri/ui";
import i18n, { TFunction } from "i18next";
import React, { Component, ReactNode } from "react";
import { ChevronLeft, ChevronRight, FastForward } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import ImageWrapper from "./../shared/components/ImageWrapper";
import FormBool from "./components/FormBool";
import FormDropdown from "./components/FormDropdown";
import FormFeedback from "./components/FormFeedback";
import FormInputNumber from "./components/FormInputNumber";
import FormMatrix from "./components/FormMatrix";
import FormRadioImage from "./components/FormRadioImage";
import FormRadioList from "./components/FormRadioList";
import FormResults from "./components/FormResults";
import FormStepper from "./components/FormStepper";
import FormSummary from "./components/FormSummary";
import { QuestionType } from "./enum/QuestionType";
import "./FormPage.scss";
import { Form } from "./models/Form";
import { FormQuestion } from "./models/FormQuestion";
import { RequirementResult, RequirementResults, } from "./models/RequirementResults";
import { DecisionTreeService } from "./services/DecisionTreeService";
import { FormService } from "./services/FormService";
import { FormUtils } from "./utils/FormUtils";
import { RichText } from 'prismic-reactjs';
import { TranslationService } from './services/TranslationService';
import FormLongText from './components/FormLongText';
import ReactGA from 'react-ga';
import FormCheckbox from "./components/FormCheckbox";
import FormTable from "./components/FormTable";
import { ReactGAUtils } from "../shared/utils/ReactGAUtils";

interface States {
  form: Form | null;
  currentQuestion: number;
  lastQuestion?: number;
  questionHistory: number[];
  values: Record<string, any>;
  summary: boolean;
  displaySummaryStep: boolean;
  results: RequirementResult[] | null;
  waitValue: boolean;
  defaultValue: any;
  conditionalInfo: string;
  valueList: string[];
  subQuestionsValues: object;
  currentSubQuestion: string;
  defaultValuesTable: {};
}

interface Props extends WithTranslation, RouteComponentProps {
}

class FormPage extends Component<Props, States> {
  private formService: FormService;
  private translationService: TranslationService;
  private decisionTreeService: DecisionTreeService;
  private t: TFunction;

  private historyListener: () => void;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.translationService = new TranslationService();
    this.decisionTreeService = new DecisionTreeService();
    this.t = this.props.t;
    this.historyListener = () => {
    };
    this.state = {
      form: null,
      currentQuestion: 0,
      questionHistory: [],
      values: {},
      summary: false,
      displaySummaryStep: false,
      results: null,
      waitValue: false,
      defaultValue: null,
      conditionalInfo: '',
      valueList: [],
      subQuestionsValues: {},
      currentSubQuestion: '',
      defaultValuesTable: {}
    };
  }

  get hasPreviousPage() {
    return this.state.currentQuestion !== 0;
  }

  get hasNextPage() {
    return !this.state.summary;
  }

  get isLastQuestion() {
    return this.state.summary || !this.state.lastQuestion || this.state.currentQuestion === this.state.lastQuestion;
  }

  get canSubmitQuestion() {
    const question = this.state.form?.questions[this.state.currentQuestion];

    if (!question) {
      return false;
    }

    if (question.optional) {
      return (
        (this.getCurrentValue() !== null) &&
        !this.state.waitValue &&
        !this.state.summary &&
        true
      );
    }

    if (question.type === QuestionType.TEXT_ONLY) {
      return true;
    }

    if (question.type === QuestionType.TABLE && this.state.values[question.question]?.length === 0) {
      return false;
    }

    let isValid = true;
    let requiredOptionnal = false;
    if (question) {
      switch (question.type) {
        case QuestionType.NUMBER:
          if (this.getCurrentValue() === '') {
            isValid = false;
            break;
          }
          if (question.validation.min !== undefined && question.validation.min !== null && isValid) {
            isValid = this.getCurrentValue() >= question.validation.min;
          }
          break;
        case QuestionType.LONG_TEXT:
          requiredOptionnal = true;
          break;
        case QuestionType.CHECKBOX:
          isValid = this.state.valueList.length > 0;
          break;
        case QuestionType.MULTIPLE:
          isValid = this.getSubQuestionValid(question);
          requiredOptionnal = true;
          break;
        case QuestionType.TABLE:
          let validRows = 0;
          if (this.state.values[question.question]) {
            for (const rowValues of this.state.values[question.question]) {
              let isRowValid = false;
              if(this.state.form?.questions[this.state.currentQuestion].subQuestions) {
                // @ts-ignore
                for (const subQ of this.state.form?.questions[this.state.currentQuestion].subQuestions) {
                  if (!subQ.optional && rowValues[subQ.question]) {
                    isRowValid = true;
                  }
                  else if (subQ.optional) {
                    isRowValid = true;
                  }
                  else {
                    if (subQ.type !== 'previous_question') {
                      isRowValid = false;
                    }
                  }
                }
              }
              if (isRowValid) {
                validRows++;
              }
            }
          }
          if (this.state.form?.questions[this.state.currentQuestion].size.type === 'fixed') {
            isValid = (this.state.values[question.question] && this.state.form?.questions[this.state.currentQuestion].size.value === validRows);
          }
          if (this.state.form?.questions[this.state.currentQuestion].size.type === 'previous_question') {
            const targetQuestion = this._findQuestionByName(this.state.form?.questions[this.state.currentQuestion].size.target);
            if (targetQuestion) {
              isValid = (this.state.values[question.question] && parseInt(this.state.values[targetQuestion.question]) === validRows);
            }
            else {
              isValid = false;
            }

          }
          else {
            isValid = (this.state.values[question.question] && this.state.values[question.question].length === validRows);
          }
      }
    }

    question.isValid = isValid;

    return (
      (this.getCurrentValue() !== null || requiredOptionnal) &&
      !this.state.waitValue &&
      !this.state.summary &&
      isValid
    );
  }

  private _findQuestionByName(name: string) {
    if (this.state.form?.questions) {
      for (const qst of this.state.form.questions) {
        if (qst.question === name) {
          return qst;
        }
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp.bind(this));

    // = Listen to the history to prevent back action
    // Clone
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: this.props.location.search,
    });

    // 'even' : allows you to execute the "previous" action only once when going backwards
    let even = 0;
    this.historyListener = this.props.history.listen((location, action) => {
      // Back action
      if (action === "POP") {
        this.props.history.go(1);

        if (this.state.results) {
          this.gotoSummary();
        }
        else {
          even++;
          if (even % 2 === 0) {
            this.handlePreviousClick(this.state.summary);
            even = 0;
          }
        }
      }
    });

    this.props.i18n.on("languageChanged", this.generateImage.bind(this));

    this.formService.getForm("default").then((resultQuestions) => {
      const form = resultQuestions.data;
      for (const question in form.questions) {
        if (form.questions[question].images && form.questions[question].images.length > 0) {
          form.questions[question].refImages = form.questions[question].images.slice(0);
        }
        if (form.questions[question].imageValues && form.questions[question].imageValues.length > 0) {
          form.questions[question].refImageValues = form.questions[question].imageValues.slice(0);
        }
      }
      this.generateImage(i18n.language, form);
    });
  }

  async generateImage(code: string, form: Form) {
    if (!form) {
      form = this.state.form ? this.state.form : {} as Form;
    }
    for (const question in form.questions) {
      for (const i in form.questions[question].refImages) {
        const translation = this.formService.t(this.t, form.questions[question].refImages[i], false, false, false, true);
        if (translation) {
          form.questions[question].images[i] = translation;
        }
      }
      for (const i in form.questions[question].refImageValues) {
        const translation = this.formService.t(this.t, form.questions[question].refImageValues[i], false, false, false, true);
        if (translation) {
          form.questions[question].imageValues[i] = translation;
        }
      }
    }
    this.setState({ form: form });
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp.bind(this));
    // Unregister history listener
    this.historyListener();
  }

  /**
   * Handle: reset
   *
   * Reset the form
   */
  handleReset() {
    this.setState({
      questionHistory: [],
      currentQuestion: 0,
      results: null,
      summary: false,
      values: {},
      displaySummaryStep: false,
      waitValue: false,
    });
  }

  gotoSummary() {
    ReactGA.event({
      category: ReactGAUtils.getCategory(),
      action: 'Affichage',
      label: 'Affichage récapitulatif'
    });

    this.setState({ summary: true, results: null });
  }

  /**
   * Handle: keyup event
   *
   * Submit next question and form
   */
  handleKeyUp(e: KeyboardEvent) {
    if (e.key.toLowerCase() === "enter" && this.canSubmitQuestion) {
      this.handleNextClick();
    }
  }

  /**
   * Handle: onValueChange/onChange
   */
  handleValueChange(value: string | number, currentSubQuestion: any, nextQuestionOnFill: boolean = false) {
    const question = this.state.form?.questions[this.state.currentQuestion];

    if (question && value !== null) {
      // Add value
      const lastValues = this.state.values;
      const values = Object.assign({}, lastValues);
      values[question.question] = value;

      if (question.type === QuestionType.CHECKBOX) {
        if (this.state.valueList.indexOf(value.toString()) === -1) {
          this.state.valueList.push(value.toString());
        }
        else {
          const index = this.state.valueList.indexOf(value.toString());
          if (index > -1) {
            this.state.valueList.splice(index, 1);
          }
        }
      }

      if (question.type === QuestionType.MULTIPLE) {
        this.state.subQuestionsValues[currentSubQuestion.question] = value;
        this.setState({
          currentSubQuestion: currentSubQuestion.question
        });
      }

      // Remove next questions in the history
      let questionHistory = this.state.questionHistory;
      const questionIndex = questionHistory.findIndex(
        (id: number) => id === this.state.currentQuestion
      );
      if (questionIndex !== -1) {
        questionHistory.splice(
          questionIndex + 1,
          questionHistory.length - (questionIndex + 1)
        );
        questionHistory = questionHistory.slice(0);
      }

      if (question.type === QuestionType.CHECKBOX) {
        values[question.question] = this.state.valueList;
      }
      else if (question.type === QuestionType.MULTIPLE) {
        if (question.subQuestions) {
          values[question.question] = {};
          for (const qst of question.subQuestions) {
            values[qst.question] = this.state.subQuestionsValues[qst.question];
            values[question.question][qst.question] = this.state.subQuestionsValues[qst.question];
          }

        }
      }

      this.setState({
        values,
        questionHistory,
        summary: false,
        displaySummaryStep: false,
      });

      if (nextQuestionOnFill) {
        setTimeout(() => {
          if (question !== this.state.form?.questions[this.state.currentQuestion]) {
            return;
          }
          if (this.canSubmitQuestion) {
            this.handleNextClick();
          }
        }, 0);
      }
    }

  }

  /**
   * Handle: onClick for the next/summary button
   */
  async handleNextClick(goToLast?: boolean) {
    if (!this.state.form || this.state.summary) {
      return;
    }

    this.setState({
      waitValue: true,
      valueList: []
    });

    ReactGA.event({
      category: ReactGAUtils.getCategory(),
      action: 'click',
      label: 'Question suivante'
    });

    // = Check conditions
    const nextQuestion = await this.getNextQuestion(
      this.state.currentQuestion,
      this.state.form.questions,
      goToLast ?? false
    );

    // = Access to summary
    if (nextQuestion.goToSummary) {
      ReactGA.event({
        category: ReactGAUtils.getCategory(),
        action: 'Affichage',
        label: 'Affichage récapitulatif'
      });

      this.setState({
        questionHistory: nextQuestion.questionHistory,
        summary: true,
        waitValue: false,
      });
      return;
    }
    const currentQuestion = goToLast ? nextQuestion.lastQuestion : nextQuestion.nextQuestion;
    /*if(goToLast) {
      //add last question in values and in questionHistory
      let v = this.state.values;
      let qH;
      const q = this.state.form.questions[nextQuestion.lastQuestion]
      v[q.question] = '';
      if(!this.state.questionHistory.includes(nextQuestion.lastQuestion)) {
        qH = this.state.questionHistory;
        qH.push(nextQuestion.lastQuestion)
      }
      this.setState({
        values: v,
        questionHistory: qH ?? nextQuestion.questionHistory,
        summary: true,
        waitValue: false,
      });
      return;
    }*/

    this.setState({
      valueList: (this.state.values[this.state.form.questions[currentQuestion].question]) ? this.state.values[this.state.form.questions[currentQuestion].question] : []
    });

    Promise.all([
      this.computeDefaultValue(currentQuestion, nextQuestion.questionHistory),
      this.computeConditionalInfo(currentQuestion, nextQuestion.questionHistory),
    ])
      .then((data: any) => {
        window.scroll(0, 0);
        this.setState({
          currentQuestion,
          lastQuestion: nextQuestion.lastQuestion,
          questionHistory: nextQuestion.questionHistory,
          waitValue: false,
          defaultValue: data[0],
          conditionalInfo: data[1]
        });
      });
  }

  /**
   * Handle: onClick for the previous button
   */
  async handlePreviousClick(fromSummary: boolean = false) {
    if (!this.state.form || this.state.currentQuestion === 0) {
      return;
    }

    const questionHistory = this.state.questionHistory;
    const questionIndex = questionHistory.findIndex(
      (id: number) => id === this.state.currentQuestion
    );
    let previousQuestion = questionHistory[questionHistory.length - 1];
    if (questionIndex !== -1 && !this.state.summary) {
      previousQuestion = questionHistory[questionIndex - 1];
    }

    this.setState({
      valueList: (this.state.values[this.state.form.questions[previousQuestion].question]) ? this.state.values[this.state.form.questions[previousQuestion].question] : []
    });

    Promise.all([
      this.computeDefaultValue(previousQuestion),
      this.computeConditionalInfo(previousQuestion),
    ])
      .then((data: any) => {
        this.setState({
          currentQuestion: previousQuestion,
          displaySummaryStep: fromSummary,
          summary: false,
          defaultValue: data[0],
          conditionalInfo: data[1]
        });
      });
  }

  private getQuestionHistoryByQuestion(questionId) {
    const questionHistory: number[] = [];
    for (const questionHistoryId of this.state.questionHistory) {
      if (questionHistoryId < questionId) {
        questionHistory.push(questionHistoryId);
      }
    }
    return questionHistory;
  }

  async computeDefaultValue(questionIndex: number, questionHistory?: number[]) {
    const question = this.state.form?.questions[questionIndex];
    if (question) {
      if (
        question.default_value &&
        question.default_value.decision_tree
      ) {
        const history = questionHistory ? questionHistory : this.getQuestionHistoryByQuestion(questionIndex);
        return await this.decisionTreeService.computeDecisionTree(
          question.default_value.decision_tree,
          FormUtils.getValidValues(this.state.form ? this.state.form.questions : [], this.state.values, history, questionIndex)
        );
      }
      else if (question.type === QuestionType.TABLE) {
        if (question.subQuestions) {
          const datas = {};
          for (const qst of question.subQuestions) {
            if (qst.type === QuestionType.DECISION_TREE) {
              const history = questionHistory ? questionHistory : this.getQuestionHistoryByQuestion(questionIndex);
              datas[qst.question] = await this.decisionTreeService.computeDecisionTree(
                qst.target,
                FormUtils.getValidValues(this.state.form ? this.state.form.questions : [], this.state.values, history, questionIndex)
              );
            }
          }
          // Add data to values
          if (!this.state.values[question.question]) {
            this.state.values[question.question] = [];
          }
          for (const key of Object.keys(datas)) {
            for (const i in datas[key]) {
              if (!this.state.values[question.question][i]) {
                this.state.values[question.question].push({ [key]: datas[key][i] });
              }
              else {
                /*if(!this.state.values[question.question][i][key]) {
                  this.state.values[question.question][i][key] = datas[key][i];
                }*/
                this.state.values[question.question][i][key] = datas[key][i];
              }
            }
          }

          return datas;
        }
      }
    }
    return null;
  }

  async computeConditionalInfo(questionIndex: number, questionHistory?: number[]) {
    const question = this.state.form?.questions[questionIndex];
    if (question) {
      let result = '';
      // If empty conditionalInfo return empty string
      if (!question.conditionalInfo || (Array.isArray(question.conditionalInfo) && question.conditionalInfo.length === 0)) {
        return result;
      }

      const history = questionHistory ? questionHistory : this.getQuestionHistoryByQuestion(questionIndex);
      const cache = new Map();
      const values = FormUtils.getValidValues(this.state.form ? this.state.form.questions : [], this.state.values, history, questionIndex);

      for (const conditionalInfo of question.conditionalInfo) {
        if (await FormUtils.checkCondition(
          this.decisionTreeService,
          conditionalInfo.conditions.lhs,
          conditionalInfo.conditions.operator,
          conditionalInfo.conditions.rhs,
          (question.type === QuestionType.CHECKBOX) ? question.values : values,
          cache
        )) {
          if (result !== '') {
            result += '<br/>';
          }
          result += this.formService.t(this.t, conditionalInfo.info, true, false, true);
        }
      }

      return result;
    }

    return '';
  }

  getSubQuestionValid(question: FormQuestion): boolean {
    if (question.subQuestions) {
      for (const subQ of question.subQuestions) {
        if (subQ.question === this.state.currentSubQuestion) {
          subQ.isValid = true;
        }
      }
      for (const qst of question.subQuestions) {
        if (!qst.optional && !qst.isValid) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  /**
   * Handle: onSubmitForm
   *
   * Reset the form
   */
  handleSubmitForm(results: RequirementResults) {
    ReactGA.event({
      category: ReactGAUtils.getCategory(),
      action: 'Affichage',
      label: 'Affichage résultat'
    });

    this.setState({
      summary: false,
      results: results.results,
    });
  }

  /**
   * Handle: onStepClick (FormStepper) & onQuestionClick (FormSummary)
   *
   * Navigate to the desired question
   */
  handleQuestionClick(questionId: number, fromSummary: boolean = false) {
    ReactGA.event({
      category: ReactGAUtils.getCategory(),
      action: 'click',
      label: 'stepper'
    });
    // if summary
    if (questionId === -1) {
      this.gotoSummary();
      return;
    }

    if (this.state.form) {
      this.setState({
        valueList: (this.state.values[this.state.form.questions[questionId].question]) ? this.state.values[this.state.form.questions[questionId].question] : []
      });
    }

    Promise.all([
      this.computeDefaultValue(questionId),
      this.computeConditionalInfo(questionId),
    ])
      .then((data: any) => {
        this.setState({
          currentQuestion: questionId,
          displaySummaryStep: this.state.displaySummaryStep || fromSummary,
          summary: false,
          defaultValue: data[0],
          conditionalInfo: data[1]
        });
      });
  }

  /**
   * Get value for a question
   */
  getValue(type: QuestionType, question: string) {
    const answers = this.state.values;
    if (!answers || answers[question] === undefined) {
      switch (type) {
        case QuestionType.NUMBER:
          return 0;
        default:
          return null;
      }
    }
    return answers[question];
  }

  /**
   * Get value for the current question
   */
  getCurrentValue() {
    const question = this.state.form?.questions[this.state.currentQuestion];
    if (!question) {
      return null;
    }
    const currentValue = this.getValue(question.type, question.question);

    if (question.type === QuestionType.NUMBER && currentValue === 0) {
      return null;
    }

    return currentValue;
  }

  /**
   * Create question elements
   */
  createElementQuestion(
    question: FormQuestion,
    value: any,
    handleValueChange: (v: any, p?: FormQuestion | null, f?: boolean) => void,
    isSubQuestion?: boolean
  ): ReactNode {
    switch (question.type) {
      case QuestionType.STRING:
        return (
          <Input.Text
            id={ question.question }
            onChange={ (v) => {
              handleValueChange(v.target.value, question, (!isSubQuestion));
            } }
            value={ (isSubQuestion) ? (this.state.values[question.question] ? this.state.subQuestionsValues[question.question] : '') : value }
          >

          </Input.Text>
        );
      case QuestionType.RADIO_IMAGE:
        return (
          <FormRadioImage
            currentValue={ (isSubQuestion) ? this.state.subQuestionsValues[question.question] : value }
            images={ question.imageValues }
            values={ question.values }
            onValueChange={ (v) => handleValueChange(v, question, (!isSubQuestion)) }
          />
        );
      case QuestionType.RADIO:
        return (
          <FormRadioList
            name={ question.question }
            currentValue={ (isSubQuestion) ? this.state.subQuestionsValues[question.question] : value }
            values={ question.values }
            onValueChange={ (v) => handleValueChange(v, question, (!isSubQuestion)) }
          />
        );
      case QuestionType.DROPDOWN:
        return (
          <FormDropdown
            name={ question.question }
            currentValue={ (isSubQuestion) ? this.state.subQuestionsValues[question.question] : value }
            values={ question.values }
            onValueChange={ (v) => handleValueChange(v, question, (!isSubQuestion)) }
            isSubQuestion={ isSubQuestion }
          />
        );
      case QuestionType.MATRIX:
        return (
          <FormMatrix
            currentValue={ (isSubQuestion) ? this.state.subQuestionsValues[question.question] : value }
            images={ question.imageValues }
            values={ question.values }
            onValueChange={ (v) => handleValueChange(v, question, (!isSubQuestion)) }
          />
        );
      case QuestionType.BOOL:
        return (
          <FormBool
            name={ question.question }
            currentValue={ (isSubQuestion) ? this.state.subQuestionsValues[question.question] : value }
            onValueChange={ (v) => handleValueChange(v, question, (!isSubQuestion)) }
          />
        );
      case QuestionType.NUMBER:
        return (
          <FormInputNumber
            question={ question }
            value={ (isSubQuestion) ? (this.state.values[question.question] ? this.state.subQuestionsValues[question.question] : 0) : value }
            onValueChange={ (v) => handleValueChange(v, question) }
            onBlur={ (question, v) => {
              if (question !== this.state.form?.questions[this.state.currentQuestion].question) {
                return;
              }
              return handleValueChange(v);
            } }
          />
        );
      case QuestionType.LONG_TEXT:
        return (
          <FormLongText
            question={ question }
            value={ (isSubQuestion) ? this.state.subQuestionsValues[question.question] : value }
            onValueChange={ (v) => handleValueChange(v, question) }
          />
        );
      case QuestionType.CHECKBOX:
        return (
          <FormCheckbox
            name={ question.question }
            question={ question }
            values={ value }
            currentValues={ question.values }
            onValueChange={ (v) => handleValueChange(v, question) }
          />
        );
      case QuestionType.MULTIPLE:
        if (question.subQuestions) {
          let questions: ReactNode[] = [];
          for (const qst of question.subQuestions) {
            const style = { fontWeight: 'bold', marginBottom: '10px' };
            questions.push(React.createElement('p', { className: 'questionTitle', style }, this.formService.t(this.t, qst.shortTitle, true, false)));

            questions.push(this.createElementQuestion(
              qst,
              this.getValue(qst.type, qst.question),
              this.handleValueChange.bind(this),
              true
            ));
            questions.push(React.createElement('br'));
            questions.push(React.createElement('br'));
          }
          return questions;
        }
        break;
      case QuestionType.TABLE:
        if (typeof this.state.values[question.size.target] === 'object') {
          if (this.state.values[question.question] &&
            (this.state.values[question.question].length >
              this.state.values[question.size.target].length)) {

            while (this.state.values[question.question].length >
            this.state.values[question.size.target].length) {
              this.state.values[question.question].pop();
            }
          }
        }
        else {
          if (this.state.values[question.question] &&
            (this.state.values[question.question].length >
              this.state.values[question.size.target])) {

            while (this.state.values[question.question].length >
            this.state.values[question.size.target]) {
              this.state.values[question.question].pop();
            }
          }
        }
        return <FormTable
          name={ question.question }
          question={ question }
          values={ value ?? [] }
          currentValues={ question.values }
          onValueChange={ (v) => {
            handleValueChange(v, question);
          } }
          questionHistory={ this.state.values }
          questions={ this.state.form?.questions }
          onSizeChange={ () => handleValueChange(value, question) }
        />;
    }
  }

  /**
   * Check the conditions and return the next question
   */
  async getNextQuestion(
    index: number,
    questions: FormQuestion[],
    goToLast: boolean
  ): Promise<{ nextQuestion: number, lastQuestion: number, questionHistory: number[], goToSummary?: boolean }> {
    if (!this.state.form) {
      return {
        nextQuestion: this.state.currentQuestion,
        lastQuestion: this.state.currentQuestion,
        questionHistory: this.state.questionHistory
      };
    }

    const cache = new Map();

    let nextQuestion;
    let lastQuestion;
    let questionHistory = this.state.questionHistory.slice(0);
    if (!questionHistory.includes(this.state.currentQuestion)) {
      questionHistory.push(this.state.currentQuestion);
    }
    for (let i = index + 1; i < questions.length; i++) {
      let values = FormUtils.getValidValues(this.state.form.questions, this.state.values, questionHistory, i);
      const question = questions[i];
      if (!question.conditions || await FormUtils.checkCondition(
        this.decisionTreeService,
        question.conditions.lhs,
        question.conditions.operator,
        question.conditions.rhs,
        values,
        cache
      )) {
        if (!nextQuestion) {
          nextQuestion = i;
        }
        lastQuestion = i;

        if (goToLast && questions.length - 1 === lastQuestion) {
          return {
            nextQuestion: questions.length - 1,
            lastQuestion: questions.length - 1,
            questionHistory,
            goToSummary: true
          };
        }

        if ((question.isValid && this.state.values[question.question] !== undefined)) {
          if (!questionHistory.includes(i)) {
            questionHistory.push(i);
          }
          continue;
        }

        if ((question.optional || question.type === QuestionType.TEXT_ONLY) && questionHistory.includes(i)) {
          continue;
        }

        return { nextQuestion, lastQuestion, questionHistory };
      }
    }
    return lastQuestion ? { nextQuestion, lastQuestion, questionHistory } : {
      nextQuestion: questions.length - 1,
      lastQuestion: questions.length - 1,
      questionHistory,
      goToSummary: true
    };
  }

  render() {

    if (!this.state.form) {
      return <div>Loading...</div>;
    }

    //
    // = SUMMARY
    //

    if (this.state.summary) {
      return (
        <FormSummary
          form={ this.state.form }
          history={ this.state.questionHistory }
          values={ FormUtils.getValidValues(this.state.form.questions, this.state.values, this.state.questionHistory, this.state.currentQuestion, true) }
          onSubmitForm={ (results: RequirementResults) =>
            this.handleSubmitForm(results)
          }
          onPreviousClick={ () => this.handlePreviousClick(true) }
          onQuestionClick={ (questionId: number) =>
            this.handleQuestionClick(questionId, true)
          }
        />
      );
    }

    //
    // = RESULTS
    //
    if (this.state.results) {
      return (
        <FormResults
          form={ this.state.form }
          history={ this.state.questionHistory }
          values={ FormUtils.getValidValues(this.state.form.questions, this.state.values, this.state.questionHistory, this.state.currentQuestion) }
          results={ this.state.results }
          onResetClick={ () => this.handleReset() }
          onPreviousClick={ () => this.gotoSummary() }
        />
      );
    }

    //
    // = FORM
    //
    const question = (this.state.form.questions) ? this.state.form.questions[this.state.currentQuestion] : null;
    if (!question) {
      return (
        <div id="center">
          <p>Loading...</p>
        </div>
      );
    }
    const el: ReactNode = this.createElementQuestion(
      question,
      this.getValue(question.type, question.question),
      this.handleValueChange.bind(this)
    );

    // Image
    let imageNodes: ReactNode[] = [];

    if (question.images.length) {
      for (let i = 0; i < question.images.length; i++) {
        const image = question.images[i];
        imageNodes.push(
          <div key={ "image-" + i } className="p-col p-d-flex p-jc-center">
            <ImageWrapper src={ image }
                          alt={ this.formService.t(this.t, question.question, false) }/>
          </div>
        );
      }
    }

    return (
      <React.Fragment>
        <FormFeedback/>
        <FormStepper
          form={ this.state.form }
          currentQuestion={ this.state.currentQuestion }
          displaySummaryStep={ this.state.displaySummaryStep }
          history={ this.state.questionHistory }
          values={ this.state.values }
          onStepClick={ (questionId: number) =>
            this.handleQuestionClick(questionId)
          }
        />
        <Question>
          <Question.Title>{ this.formService.t(this.t, question.question, true) }</Question.Title>
          <Question.Body>
            { question.info && (
              <div
                className="form-question-info"
              >{ this.formService.t(this.t, question.info, true) }</div>
            ) }
            { this.state.conditionalInfo && this.state.conditionalInfo !== '' && (
              <div
                className="form-question-info"
                dangerouslySetInnerHTML={ { __html: this.state.conditionalInfo } }
              ></div>
            ) }
            <div className="p-grid p-align-center">
              { question.type === QuestionType.TEXT_ONLY ? null : (
                <div
                  className={ imageNodes.length ? "p-col-12 p-md-6" : "p-col-12" }
                >
                  <div className="form-question-input">
                    { question.default_value && this.state.defaultValue && (
                      <div className="form-question-suggestion">
                        { (this.formService.checkIfValue(RichText.render(this.t("ui_input_suggestion", { returnObjects: true })), "ui_input_suggestion")) ? (RichText.render(this.t("ui_input_suggestion", { returnObjects: true }))) : "no_value" }
                        { this.state.defaultValue }
                        { question.unit && (
                          <React.Fragment> { RichText.render(this.t(question.unit, { returnObjects: true })) }</React.Fragment>
                        ) }
                      </div>
                    ) }
                    { el }
                  </div>
                </div>
              ) }
              { imageNodes.length ? (
                <div className="p-col-12 p-md-6">
                  <div className="p-grid">{ imageNodes }</div>
                </div>
              ) : null }
            </div>
            { question.subInfo && (
              <div
                className="form-question-sub-info"
              >{ this.formService.t(this.t, question.subInfo, true) }</div>
            ) }
          </Question.Body>
        </Question>
        <div className="form-actions-wrapper">
          <div className="left-actions">
            { this.hasPreviousPage && !this.state.waitValue ? (
              <Button
                icon="pi pi-chevron-left"
                iconPos="left"
                variant="secondary"
                onClick={ () => {
                  this.handlePreviousClick();
                  ReactGA.event({
                    category: ReactGAUtils.getCategory(),
                    action: 'click',
                    label: 'Question précédente'
                  });
                } }
              >
                <React.Fragment>
                  <ChevronLeft/>
                  <span className={ 'hidden-sm' }>{ this.formService.t(this.t, "button_previous", false, true) }</span>
                </React.Fragment>
              </Button>
            ) : undefined }
          </div>
          <div className="right-actions">
            { !this.isLastQuestion && this.state.questionHistory.length !== 0 ? (
              <Button
                icon="pi pi-step-forward-alt"
                iconPos="right"
                onClick={ () => {
                  this.handleNextClick(true);
                  ReactGA.event({
                    category: ReactGAUtils.getCategory(),
                    action: 'click',
                    label: 'Bouton avance rapide'
                  });
                } }
              >
                { this.state.waitValue ? (
                  "Loading..."
                ) : (
                  <React.Fragment>
                    <span className={ 'hidden-sm' }>{ this.formService.t(this.t, "button_go_to_last", false, true) }&nbsp;</span>
                    <FastForward/>
                  </React.Fragment>
                ) }
              </Button>
            ) : undefined }
            { this.hasNextPage ? (
              <Button
                icon="pi pi-chevron-right"
                iconPos="right"
                onClick={ () => {
                  this.handleNextClick();
                  ReactGA.event({
                    category: ReactGAUtils.getCategory(),
                    action: 'click',
                    label: 'Question suivante'
                  });
                } }
                disabled={ (question.optional) ? false : !this.canSubmitQuestion }
              >
                { this.state.waitValue ? (
                  "Loading..."
                ) : (
                  <React.Fragment>
                    <span className={ 'hidden-sm' }>{ this.formService.t(this.t, "button_next", false, true) }</span>
                    <ChevronRight/>
                  </React.Fragment>
                ) }
              </Button>
            ) : undefined }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withRouter(FormPage));
