import { Button, Input } from "@bbri/ui";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { UploadCloud, X } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { FeedbackDTO } from "../dto/FeedbackDTO";
import "./FormFeedbackModal.scss";
import { RichText } from "prismic-reactjs";
import { FormService } from "../services/FormService";

interface Props extends WithTranslation {
  onSubmit: (dto: FeedbackDTO) => Promise<boolean>;
  onClose: () => void;
}

interface States {
  form: {
    comment: string;
    screenshot?: File | null;
  };
}

class FormFeedbackModal extends Component<Props, States> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);

    this.t = this.props.t;
    this.formService = new FormService();
    this.state = {
      form: {
        comment: "",
        screenshot: null,
      },
    };
  }

  handleFormChange(name: string, value: any) {
    let form = Object.assign({}, this.state.form);
    form[name] = value;

    this.setState({
      form,
    });
  }

  /**
   * Handle: onChange for upload input
   */
  handleFileChange(files: FileList | null) {
    if (files && files.length) {
      this.handleFormChange("screenshot", files[0]);
    }
  }

  /**
   * Handle: onClick for upload button
   */
  handleUploadClick() {
    const inputFileRef: HTMLInputElement | null = document.querySelector(
      ".feedback-screenshot input[type='file']"
    );
    if (inputFileRef) {
      inputFileRef.click();
    }
  }

  handleSubmit() {
    if (!this.state.form.comment) {
      return;
    }

    const dto = new FeedbackDTO(
      this.state.form.comment,
      this.state.form.screenshot
    );

    this.props.onSubmit(dto).then((result) => {
      if (result) {
        this.setState({
          form: {
            comment: "",
            screenshot: null,
          },
        });
        this.props.onClose();
      }
    });
  }

  render() {
    return (
      <div className="feedback-modal">
        <div className="feedback-modal-header">
          {this.formService.checkIfValue(RichText.render(this.t("ui_feedback_header", { returnObjects: true })), "ui_feedback_header")}
          <Button variant="action" onClick={() => this.props.onClose()}>
            <X/>
          </Button>
        </div>
        <div className="feedback-modal-body">
          <p
            className="feedback-content">{this.formService.checkIfValue(RichText.render(this.t("ui_feedback_content", { returnObjects: true })), "ui_feedback_content")}</p>
          <div className="feedback-comment">
            <label htmlFor="feedback-comment-textarea">
              {this.formService.checkIfValue(RichText.render(this.t("ui_feedback_form_comment_label", { returnObjects: true })), "ui_feedback_form_comment_label")}
            </label>
            <Input.TextArea
              id="feedback-comment-textarea"
              value={this.state.form.comment}
              placeholder={this.formService.t(this.t, "ui_feedback_form_comment_placeholder", false)}
              onChange={(e) => this.handleFormChange("comment", e.target.value)}
              required
            />
          </div>
          <div className="feedback-screenshot">
            <Button onClick={() => this.handleUploadClick()}>
              <UploadCloud/>
              {this.formService.checkIfValue(RichText.render(this.t("ui_feedback_form_upload_screenshot_button", { returnObjects: true })), "ui_feedback_form_upload_screenshot_button")}
            </Button>
            <div className="feedback-screenshot-content">
              {this.state.form.screenshot
                ? this.state.form.screenshot.name
                : this.formService.t(this.t, "ui_feedback_form_upload_screenshot_no_image", false)}
            </div>
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              hidden
              onChange={(e) => this.handleFileChange(e.target.files)}
            />
          </div>
        </div>
        <div className="feedback-modal-footer">
          <Button variant="tertiary" onClick={() => this.props.onClose()}>
            {this.formService.checkIfValue(RichText.render(this.t("ui_feedback_button_cancel", { returnObjects: true })), "ui_feedback_button_cancel")}
          </Button>
          <Button variant="primary" onClick={() => this.handleSubmit()}>
            {this.formService.checkIfValue(RichText.render(this.t("ui_feedback_button_send", { returnObjects: true })), "ui_feedback_button_send")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FormFeedbackModal);
