export enum QuestionType {
  RADIO = 'radio',
  RADIO_IMAGE = 'radio_image',
  DROPDOWN = 'dropdown',
  BOOL = 'bool',
  NUMBER = 'number',
  LONG_TEXT = 'long_text',
  MATRIX = 'matrix',
  CHECKBOX = 'checkbox',
  MULTIPLE = 'multiple',
  TABLE = 'table',
  STRING = 'string',
  PREVIOUS_QUESTION = 'previous_question',
  TEXT_ONLY = 'text_only',
  DECISION_TREE = 'decision_tree'
}
