import { APIService } from '../../shared/services/APIService';
import { FeedbackDTO } from '../dto/FeedbackDTO';

export class FeedbackService extends APIService {


  submitFeedback(dto: FeedbackDTO) {
    const formData = new FormData();
    formData.append('comment', dto.comment);
    if (dto.screenshot) {
      formData.append('screenshot', dto.screenshot);
    }
    return this.http.post('/feedback', formData);
  }
}