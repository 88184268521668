import {APIService} from "../../shared/services/APIService";
import {AxiosResponse} from "axios";
import {Html} from "../../admin/models/HTML";

export class FileService extends APIService {
    /**
     * Get one html by name
     */
    getOne(name: string): Promise<AxiosResponse<Html>> {
        return this.http.get<Html>(`/htmls/${name}/file`);
    }
}
