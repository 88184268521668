import { Toast } from "primereact/toast";
import React, { Component } from "react";
import { MessageCircle } from "react-feather";
import { WithTranslation, withTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import FormFeedbackModal from "../components/FormFeedbackModal";
import { FeedbackDTO } from "../dto/FeedbackDTO";
import { FeedbackService } from "../services/FeedbackService";
import "./FormFeedback.scss";
import { FormService } from '../services/FormService';

interface Props extends WithTranslation {
}

interface States {
  isOpen: boolean;
}

class FormFeedback extends Component<Props, States> {
  private toast: Toast | null;
  private feedbackService: FeedbackService;
  private formService: FormService;

  constructor(props: Props) {
    super(props);

    this.formService = new FormService();
    this.feedbackService = new FeedbackService();
    this.toast = null;

    this.state = {
      isOpen: false,
    };
  }

  /**
   * Handle: onClick
   */
  async handleSubmit(dto: FeedbackDTO): Promise<boolean> {
    return this.feedbackService
      .submitFeedback(dto)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.formService.t(this.props.t, "ui_feedback_toast_success", false, false),
        });
        return true;
      })
      .catch(() => {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: this.formService.t(this.props.t, "ui_feedback_toast_error", false, false),
        });
        return false;
      });
  }

  render() {
    return (
      <React.Fragment>
        <Toast ref={(el) => (this.toast = el)}/>
        <button
          className={
            this.state.isOpen
              ? "feedback-button feedback-open"
              : "feedback-button"
          }
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <MessageCircle/>
        </button>
        {this.state.isOpen && (
          <FormFeedbackModal
            onSubmit={(dto: FeedbackDTO) => this.handleSubmit(dto)}
            onClose={() => this.setState({ isOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(FormFeedback);
